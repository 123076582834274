
export function filterNum(num = 0) {
    if ( num < 10 )return "0"+num;
    else return num;
}

// 获取月份
export function getMonth(bool = false,key?) {
    let max = 12;
    let newList = [];
    for ( let i = 1;i <= max; i ++ ){
        let data = {
            text:filterNum(i) + " 月",
            value:i-1,
            month:i
        }
        if ( bool ) data["disabled"] = true;
        else{
            if ( key && i == key ) data["disabled"] = true;
        }
        newList.push(data)
    }
    return newList;
}
