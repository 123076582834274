
// list 数据 defaultVal 默认值 propsList 处理的参数
export function filterToArray(list = [],defaultVal = "0",...propsList) {
    let newList = [];
    Array.isArray(list) && list.map((item)=>{
        let data = []
        propsList.forEach(key=>{
            data.push({
                value:item[key] || defaultVal
            })
        })
        newList.push(data)
    })
    return newList;
}


// 参数 封装 换绑 key:准备换绑的参数  value:换绑对象的值
export function bindingJson(data:any ={},keyVal:any = {}) {
    let newData:any = {}
    try{
        let keyArr = Object.keys(keyVal);
        keyArr.length && keyArr.forEach((item,index)=>{
            try{
                newData[ keyVal[item] ] = data[item] || 0
            }catch (e) {
                console.info("单转换失败")
            }
        })
    }catch (e) {
        console.info("转化参数 失败")
    }
    return newData;
}
